import cartTypes from './cart.types';

const INITIAL_STATE = {
  cartItems: [],
  cartPacks : []
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cartTypes.ADD_TO_CART:
      return {
        ...state,
        cartItems: 
          [...state.cartItems, action.payload] 
      };
    case cartTypes.ADD_PACK_TO_CART:
      return {
        ...state,
        cartPacks: 
          [...state.cartPacks, action.payload] 
      };
    case cartTypes.REDUCE_CART_ITEM:
      return {
        ...state,
        cartItems: 
          state.cartItems.filter(item => item.id !== action.payload)
      };
    case cartTypes.REDUCE_CART_PACK:
      return {
        ...state,
        cartPacks: 
          state.cartPacks.slice(1,state.cartPacks.length )

      };
    case cartTypes.REMOVE_CART_ITEM:
      
      return {
        ...state,
        cartItems: 
          state.cartItems.filter(item => item.typeId !== action.payload)
      };
    case cartTypes.CLEAR_CART:
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
};

export default cartReducer;