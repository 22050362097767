import tripTypes from "./trip.types";

const INITIAL_STATE = {
  trips: [],
  tickets: [],
  selectedTrip : [],
  tripsForMonth : [],
  dataForDay : []
};

const tripReducer = (state = INITIAL_STATE, action) =>  {
  switch (action.type) {
    
      case tripTypes.ADD_TRIP :
        return {
          ...state,
            trips : action.payload
        }
        case tripTypes.ADD_TICKET:

        return {
          ...state,
            tickets : action.payload
        }
      case tripTypes.REMOVE_TRIP : 
        return {
          ...state,
            trips : action.payload
        }
        case tripTypes.ADD_SELECTED_TRIP : 
        return {
          ...state,
            selectedTrip : action.payload
        }
        case tripTypes.ADD_TRIPS_FOR_MONTH :
          return {
            ...state,
            tripsForMonth : action.payload
          }
        case tripTypes.ADD_DATA_FOR_DAY :
          return {
            ...state,
            dataForDay : action.payload
          }
      default :
        return state
      
  }
}
export default tripReducer