
const INITIAL_STATE = {
  orderId : ''
}

const orderReducer = (state = INITIAL_STATE, action) =>  {
  switch (action.type) {
    
      case 'ADD_ORDER' :
        return {
          ...state,
            orderId : action.payload
      }
      case 'CLEAR_ORDER' :
        return {
          ...state,
            orderId : ''
      }
      default :
        return state
      
  }
}
export default orderReducer