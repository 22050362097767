import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import "moment/locale/hu";
//import moment from 'moment';

const fallbackLng = ["hu", "en"];
const availableLanguages = ["hu", "de", "en", "ru", "zh", "it", "fr", "es", "ro", "ko", "el"];

const options = {
  // order and from where user language should be detected
  order: [
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "querystring",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  cookieOptions: { path: "/", sameSite: "strict" },
  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  // .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init(
    {
      fallbackLng,
      debug: false,
      whitelist: availableLanguages,
      lng: "hu",
      detection: options,

      interpolation: {
        escapeValue: false,
      },
    }

    /*moment.locale("hu"),*/
  );

export default i18n;
