import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ex from "../../img/icons/plus.svg";
import bg from "../../img/graphic_assets/kisfaludy-plans--filled@1280x.webp";
const Popup = ({ t }) => {
  let lang = i18next.language;
  useEffect(() => {
    if (!lang) {
      i18next.changeLanguage("hu");
      lang = "hu";
    }
  }, []);
  const isModal = useSelector((state) => state.isModal.isSeasonEnd);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(isModal);
  const [bannerVisible, setBannerVisible] = useState(false);
  const isOutOfOrder = false;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!visible) setBannerVisible(true);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [visible]);
  const isGyereknap = new Date("2024/05/27") > new Date();

  return (
    <>
      {isModal && isOutOfOrder ? (
        <div className="overlay">
          <div className="popup">
            <div className="img-container">
              <img src={bg} />
            </div>
            <div className="text-container">
              <h2>
                <span>{t("popup.seasonEnd.hp1")}</span>
                <br></br>
                <span>{t("popup.seasonEnd.hp2")}</span>
              </h2>
              <span className="subtext">{t("popup.seasonEnd.h2")}</span>
              <p className="first">{t("popup.seasonEnd.p")}</p>
              <a className="to-ticket" href={`https://www.dunacruises.com`}>
                {t("popup.seasonEnd.btn")}
              </a>
              <p
                className="close-btn"
                onClick={() => {
                  dispatch({
                    type: "SET_POPUP",
                    payload: false,
                  });
                  setVisible(false);
                }}>
                {t("modal.discount.close")}
              </p>

              <img
                className="close"
                onClick={() => {
                  dispatch({
                    type: "SET_POPUP",
                    payload: false,
                  });
                  setVisible(false);
                }}
                style={{
                  transform: "rotate(45deg)",
                  position: "absolute",
                  width: 20,
                  height: 20,
                  zIndex: 1001,
                  cursor: "pointer",
                }}
                src={ex}
              />
            </div>
          </div>
        </div>
      ) : null}
      {isOutOfOrder ? (
        <div className={`gradient-wrap ${bannerVisible ? "visible" : ""}`}>
          <div className="gradient"></div>
          <div className={`banner ${bannerVisible ? "visible" : ""}`}>
            <h3>{t("popup.banner.h")}</h3>
            <div className="text-wrapper">
              <p>
                {t("popup.banner.p1")}
                <b> {t("popup.banner.b")} </b>
                {t("popup.banner.p2")}
              </p>
              <div className="btn-wrp">
                <a className="to-ticket" href={`https://www.dunacruises.com`}>
                  {t("popup.banner.btn")}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isGyereknap && isModal ? (
        <div className="popup gyerek">
          <h3>{t("popup.gyerek.h")}</h3>
          <p className="main">{t("popup.gyerek.p1")}</p>
          <p>{t("popup.gyerek.p2")} 🙂</p>
          <p className="desc">{t("popup.gyerek.desc")}</p>
          <img
            className="close"
            onClick={() => {
              dispatch({
                type: "SET_POPUP",
                payload: false,
              });
            }}
            style={{
              transform: "rotate(45deg)",
              position: "absolute",
              width: 20,
              height: 20,
              zIndex: 1001,
              cursor: "pointer",
            }}
            src={ex}
          />
        </div>
      ) : null}
    </>
  );
};
export default Popup;
