import userTypes from './user.types'

const INITIAL_STATE = {
  name: '',
  token : ''
};

const userReducer = (state = INITIAL_STATE, action) =>  {
  switch (action.type) {
    
      case userTypes.LOGIN :
        return {
          ...state,
            name : action.payload.name,
            token : action.payload.token
        }

      case userTypes.LOGOUT : 
        return {
          ...state,
            ...INITIAL_STATE
        }
      default :
        return state
      
  }
}
export default userReducer