const INITIAL_STATE = {
  date : new Date()
};

const dateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_DATE':
      return {
        ...state,
        date : action.payload
      };
    
    default:
      return state;
  }
};

export default dateReducer;